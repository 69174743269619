.slider-container {
    width: 100%;
    max-height: 650px;
    position: relative;
    overflow: hidden;
}

.slider-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
}


.slider-content img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.arrow-button-slider {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.1rem;
    color: var(--primary-red);
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid var(--primary-red);
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 2;
    transition: background 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow-button-slider:hover {
    background: rgba(255, 255, 255, 0.9);
}

.arrow-button-slider.next {
    right: 10px;
}

.arrow-button-slider.prev {
    left: 10px;
}