.category-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 40px;
}

.categories-scroll-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: start;
    overflow: hidden;
    /* Hide default scrollbar */
    cursor: grab;
    /* Change cursor to indicate draggable area */
    white-space: nowrap;
    /* Prevent items from wrapping to a new line */
    padding: 20px 0px;
    /* Add some vertical padding if needed */
    gap: 30px;


}

.categories-scroll-view:active {
    cursor: grabbing;
    /* Change cursor to indicate dragging */
}

.category-item {
    flex: 0 0 auto;
    /* Prevent items from shrinking */
    /* margin: 0 1rem; */
    /* Space between items */
    /* padding: 1rem; */
    /* background-color: var(--primary-red); */
    /* Example background */
    /* border-radius: 8px; */
    /* Example border radius */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    /* Example shadow */
    text-align: center;
    min-width: 150px;
    /* Adjust size as needed */
    color: #000000;
    border-radius: 20px;
}

.category-img--div--fs01 {
    max-width: 150px;
    max-height: 150px;
    margin-bottom: 12px;
}

.category-img--div--fs01 img {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;
    border-radius: 0%;
}

.category-div-fs01 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    cursor: pointer;
}



.category-home--p {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    justify-content: start;
}

.category-home--p-01 {
    font-size: 1.6rem;
    /* font-family: ; */

    font-weight: 700;
}

.heading-title-div--component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 50px;
}

.heading-title--component {
    font-size: 2.2rem;
    font-weight: 800;
    /* margin-top: 40px; */
    /* font-family: ; */
}


.product--wrapper-grid-1 {
    max-width: 320px;
    max-height: 240px;
    cursor: pointer !important;
}

.product--wrapper-grid-1-mobile {
    width: 100%;
    max-height: 200px;
    /* height:; */
    cursor: pointer !important;
}

.product--wrapper-grid-1-mobile img {
    width: 100%;
    max-height: 200px;
    max-width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: 100%;
    object-fit: contain;
    cursor: pointer !important;
}

.product--wrapper-grid-1 img {
    width: 100%;
    height: 240px;
    max-width: 100%;
    max-height: 240px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer !important;
}

.product-functions--componentfs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.product--counter-quantity {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #c0c0c0;
    padding: 5px;
    border-radius: 10px;
}

.product--counter-quantity button {
    border: none;
    background-color: transparent;
    padding: 2px 5px;

}

.product--counter-quantity button:first-child {
    border-right: 1.2px solid #c0c0c0;
}

.product--counter-quantity button:last-child {
    border-left: 1.2px solid #c0c0c0;
}

.counter--state-count {
    padding: 0px 10px;
}

.booknow--fast-component {
    padding: 10px 10px;
    background-color: var(--primary-red);
    color: #fff;
    border: none;
    border-radius: 10px;
}

.product-container--wrapper {
    border-radius: 20px;
    max-width: 320px;
    /* min-width: 320px; */
    border: 1px solid #c0c0c0;
}

.product-container--wrapper-mobile {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #c0c0c0;

}

.product-container-to-slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 0px 50px;
    gap: 20px;
    overflow: hidden;
    min-height: 450px;
}

.product--wrapper-grid-2 {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.product-slider--home {
    padding: 0px 50px;
}

.productPostin {
    width: 100%;
    position: relative;
}

.positionButtonsMove {
    position: absolute;
    top: 50%;
    z-index: 1000;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-red);
    padding: 8px;
    cursor: pointer;
    background-color: #fff;
}

.leftDecrease {
    left: -10px;
}

.rightIncrease {
    right: 0px;
}

.positionButtonsMove svg {
    width: 20px;
    height: 20px;
}

.view-all--component {
    font-size: 12px;
    padding: 5px 5px;
    background-color: var(--primary-red);
    color: #fff;
    border: none;
    border-radius: 10px;
}

.product-container-to-grid-mobile {
    width: 100%;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 49.5% 49.5%;
    row-gap: 10px;
    padding: 20px 0px;
    /* overflow: scroll; */
}

.mostWanted-grid {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    padding: 0px 50px;
    gap: 20px;
}

.mostWanted-grid a img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 20px;
}

.product-grid--productstyle {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    place-items: center;
    gap: 10px;
    justify-content: center;
    padding: 10px 40px;
}

.heading-title--component-01 {
    padding-bottom: 24px;
}

.pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.butnPage {
    background-color: var(--primary-red);
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.butnPage svg {
    width: 15px;
    height: 15px;
}

.pageDet {
    background-color: transparent;
    color: #000;
    border: none;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dashboardlink--container {
    width: 100%;
    /* border: 1px solid #000; */
    background-color: #fff;
    padding: 20px 10px;
    margin-top: -30px;
    z-index: 100;
    position: relative;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(56, 56, 56, 0.75);
    -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
}

.profileDisplayInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.profilepicture--container {
    min-width: 85px;
    min-height: 85px;
    max-width: 85px;
    max-height: 85px;
    ;
    border-radius: 50%;
    border: 1px solid var(--primary-red);
    padding: 2px;

    img {
        min-width: 80px;
        min-height: 80px;
        max-width: 80px;
        max-height: 80px;
        border-radius: 50%;
        object-fit: cover;
        /* border: 1px solid #000; */
    }
}

.profileName {
    font-family: 'Lufga Bold';
}

.emailProfile {
    color: var(--primary-red);
}

.dashboardlinks--container-child {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding: 10px 0px;
}

.dashboardlinks--container-child--li {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--primary-red);
    color: var(--primary-red);
    justify-content: space-between;
    align-items: center;
}

.dashboardlinks--container-child--li:hover {
    background-color: var(--primary-red);
    color: #fff;
}

.dashboardlinks--container-child--li.active {
    background-color: var(--primary-red) !important;
    color: #fff !important;
}

.dashboardlinks--container-child--li.active:hover {
    border: 1px solid var(--primary-red) !important;
    color: var(--primary-red) !important;
    background-color: var(--primary-background-color) !important;
}