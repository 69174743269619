.auth-page-component {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
}

.left-auth-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--primary-background-color);
    position: relative;
    height: 100%;
    padding: 20px 0px 0px 0px;
}

.img-auth {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
}

.img-auth img {
    width: 60%;
    z-index: 3;
}

.start-side-content:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 16%;
    width: 51%;
    height: 60%;
    background: white;
    border-radius: 50% 50% 0px 0px;
    z-index: 2;
}

.breadcrumb-leftauth {
    padding-bottom: 30px;
    width: 100%;
    padding-left: 60px;
}

.end-side-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fffaf3;
}

.auth-component-wrapper {
    border: 1px solid #000;
    padding: 60px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
}

.welcomeTextAuth {
    margin-bottom: 20px;
}

.auth-component-wrapper label {
    display: flex;
    flex-direction: column;
    font-family: 'Lufga Medium';
    margin-bottom: 20px;
    position: relative;
}

.auth-component-wrapper label input[type='text'],
.auth-component-wrapper label input[type='password'],
.auth-component-wrapper label input[type='tel'] {
    padding: 10px 20px;
    height: calc(28px + 20px);
    min-width: 318px;
    max-width: calc(318px + 20px + 20px);
    border: 1px solid #000;
    font-size: 1rem;
    border-radius: 10px;
}

.display-checkbox-forgetpass {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

#checkbox-rememberme {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: center;
    gap: 5px;
}

#forgot-password {
    color: var(--primary-red);
    text-decoration: none;
    font-size: 12px;
}

.buttongrp-auth {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 25px;
}

.buttongrp-auth button {
    padding: 10px 20px;
    font-size: 1.1rem;
    font-family: 'Lufga Medium';
    border: none;
    border-radius: 10px;
}

#borderBackBlack {
    background-color: #000;
    color: #fff;

}

#nonBorderBackWhite {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}

.style-sendVerify-otp {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    bottom: 5px;
    border: none;
    background-color: var(--primary-background-color);
    border-radius: 10px;
}

/* TOGGLE BUTTON  */
.switches-container {
    width: 16rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: var(--switches-bg-color);
    line-height: 3rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.container-switch {
    margin-bottom: 20px;
}

.switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: var(--switches-label-color);
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.15rem;
    z-index: 3;
    transition: transform .5s cubic-bezier(.77, 0, .175, 1);
    /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
    border-radius: 3rem;
    background: var(--switch-bg-color);
    height: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
.switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: var(--switch-text-color);
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked~.switch-wrapper {
    transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked~.switch-wrapper {
    transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
}