/* Ensure the body and html take full height */
body,
html {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

/* Container holding the entire page content */
.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* Ensures it takes the full viewport height */
}



/* Footer section */
footer {
    background-color: #000;
    color: #fff;
    --mask:
        radial-gradient(60.47px at 50% 82.5px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(60.47px at 50% -52.5px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}

/* Grid container for footer */
.footer-container {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    padding: 100px 125px 30px 125px;
    margin-top: 50px;
}

/* Footer section styles */
.footer-container-sections {
    display: grid;
    width: 100%;
}

.footer-container-sections.footer-sections-3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: end;
    place-self: center;
}

.logo-footer {
    max-width: 175px;
}

.logo-footer img {
    max-width: 100%;
}

.footer-sections-contact-creds {
    min-width: 400px;
}

.footer-child--fs--1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-size: 1rem;
}

.footer-child--fs--1--social,
.footer-sections-contact-creds ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-size: 1rem;
    margin-top: 20px;
}