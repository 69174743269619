@font-face {
  font-family: 'Lufga Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga Regular'), url('./assets/fonts/lufga-webfont/LufgaRegular.woff') format('woff');
}


@font-face {
  font-family: 'Lufga Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga Italic'), url('./assets/fonts/lufga-webfont/LufgaThin.woff') format('woff');
}


@font-face {
  font-family: 'Lufga Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga Thin'), url('./assets/fonts/lufga-webfont/LufgaThin.woff') format('woff');
}





@font-face {
  font-family: 'Lufga ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga ExtraLight'), url('./assets/fonts/lufga-webfont/LufgaExtraLight.woff') format('woff');
}





@font-face {
  font-family: 'Lufga Light';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga Light'), url('./assets/fonts/lufga-webfont/LufgaLight.woff') format('woff');
}





@font-face {
  font-family: 'Lufga Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga Medium'), url('./assets/fonts/lufga-webfont/LufgaMedium.woff') format('woff');
}




@font-face {
  font-family: 'Lufga SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga SemiBold'), url('./assets/fonts/lufga-webfont/LufgaSemiBold.woff') format('woff');
}




@font-face {
  font-family: 'Lufga Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga Bold'), url('./assets/fonts/lufga-webfont/LufgaBold.woff') format('woff');
}


@font-face {
  font-family: 'Lufga ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga ExtraBold'), url('./assets/fonts/lufga-webfont/LufgaExtraBold.woff') format('woff');
}



@font-face {
  font-family: 'Lufga Black';
  font-style: normal;
  font-weight: normal;
  src: local('Lufga Black'), url('./assets/fonts/lufga-webfont/LufgaBlack.woff') format('woff');
}

:root {
  --primary-background-color: #FEEB9D;
  --primary-red: #CC0D39;
  --woocommerce: #7F54B3;
  --wc-green: #7ad03a;
  --wc-orange: #ffba00;
  --wc-blue: #2ea2cc;
  --wc-primary: #7F54B3;
  --wc-primary-text: white;
  --wc-secondary: #e9e6ed;
  --wc-secondary-text: #515151;
  --wc-highlight: #b3af54;
  --wc-highligh-text: white;
  --wc-content-bg: #fff;
  --wc-subtext: #767676;

  --switches-bg-color: #CC0D39;
  --switches-label-color: white;
  --switch-bg-color: white;
  --switch-text-color: #CC0D39;
}



.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */
  width: 100%;
}

* {
  padding: 0px;
  font-family: 'Lufga Regular';
  margin: 0px;
  box-sizing: border-box;
  list-style-type: none;
}

::selection {
  color: #fff;
  background: var(--primary-red);
}

button {
  cursor: pointer;
}