.navbar-top-container,
.navbar-bottom,
.navbar-bottom {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
}

.logo-container {
    max-height: 100px;
}

.logo-container img {
    max-height: 100px;
}

.support-icon-andtext img {
    max-width: 50px;
    max-height: 50px;
}

.support-icon-andtext {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.textpart-spic {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.textPart-247-spic {
    color: var(--primary-red);
}

.titledHere-spic {
    font-size: 1rem;
    font-family: 'Lufga Bold';
}

.product-search-input-navbar {
    background-color: #fff;
    border-radius: 10px;
    padding: 5px 10px;
}

.product-search-input-navbar {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

}

.psin-category-dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 10;
    background-color: white;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.dropdown-menu a {
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
    left: 10px;
    border-radius: 10px;
    background-color: #fff;

}

.dropdown-menu a:hover {
    background-color: var(--primary-red);
    color: #fff;
}

.input--product-search {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    border: none;
    font-size: 1rem;
}

.psin-category-dropdown {
    position: relative;
    width: 145px;
    max-width: 150px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 05px 10px 5px 10px;
    border-right: 1px solid #c0c0c0;

    span {
        color: var(--primary-red);
    }
}

.categoryMainDropdown--psin {
    font-size: 1rem;
    color: var(--primary-red);
}

.inputproduct-div--search {
    position: relative;
    width: 536.633px;
}

.faSearchHAbsolute {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: var(--primary-red);
    background-color: transparent;
    border: none;
}

.faSearchHAbsolute svg {
    height: 20px;
    width: 20px;
}

.navbar-bottom {
    padding: 10px 60px;
}

.icons-wish-search-cart {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.menu-items-navigation {
    display: flex;
    flex-direction: row;
    /* gap: 20px; */
}

.menu-item {
    position: relative;
}

.main-menu-link {
    text-decoration: none;
    color: black;
    padding: 10px 20px;
    transition: background-color 0.3s, color 0.3s;
}

.main-menu-link:hover {
    background-color: var(--primary-red);
    color: #fff;
    border-radius: 5px;
}

.sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    /* padding: 10px; */
    border-radius: 5px;
}

.sub-menu a {
    text-decoration: none;
    color: black;
    padding: 10px 20px;
    transition: background-color 0.3s, color 0.3s;
}

.sub-menu a:hover {
    background-color: var(--primary-red);
    color: #fff;
    border-radius: 5px;
}

.menu-item:hover .sub-menu,
.menu-item:focus-within .sub-menu {
    display: flex;
}

.icons-wish-search-cart div button {
    background-color: transparent;
    border: none;
    position: relative;

    svg {
        color: #000;
        height: 22px;
        width: 22px;
    }
}

.navbar-bottom-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.categories-fetch-show {
    background-color: var(--primary-background-color);
    font-size: 16px;
    min-width: 200px;
    /* max-width: 300px; */
    letter-spacing: 0.1em;
    border: none;
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 20px;

    svg {
        height: 20px;
        width: 20px;
    }
}

.notificationIconsonIcons {
    position: absolute;
    top: -6.5px;
    right: -5px;
    font-size: 9px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 16px;
    min-width: 16px;
    font-weight: 700;
    background-color: var(--primary-red);
    border-radius: 50%;
    color: #fff;
}

.menu-item-drop::after {
    content: "";
    margin-left: 5px;
    margin-top: -5px;
    background: url(data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0L7.69706 4.30294L12 6L7.69706 7.69706L6 12L4.30294 7.69706L0 6L4.30294 4.30294L6 0Z" fill="black"/></svg>);
    display: inline-block;
    width: 12px;
    height: 12px;

}


/* CODE FOR MOBILE  */
.logo-mobile {
    height: 100px;
}

.logo-mobile img {
    height: 100%;
}

.logo-mobile.navbar-mobileloggo {
    max-height: 65px;
}

.navbar-top-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px;
    border-bottom: 1px solid #c0c0c0;
}

.hamburger-menu button {
    background-color: transparent;
    color: #000;
    padding: 5px 7px;
    border: none;
    border-radius: 5px;
}

.hamburger-menu button svg {
    height: 30px;
    width: 30px;
}

.iconsNavbar-mobile {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.iconsNavbar-mobile a svg {
    color: #000;
    height: 25px;
    width: 25px;
}

.mobile-side-menu {
    position: fixed;
    top: 0;
    left: -350px;
    width: 350px;
    height: 100%;
    background-color: #000;
    color: white;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
    padding: 20px;
    overflow-y: auto;
    /* Allow vertical scrolling */
    max-height: 100vh;
    /* Constrain height to viewport */
}

.mobile-side-menu.open {
    left: 0;
}

.mobile-side-menu ul {
    list-style: none;
    padding: 0;
}

.mobile-side-menu li {
    margin-top: 20px;
    margin-bottom: 10px;
}

.mobile-side-menu a {
    color: white;
    text-decoration: none;
    font-family: 'Lufga Medium';
}

.subItem-MobileSide {
    border: 1px solid #fff;
}

.subItem-item--mobile {
    font-family: 'Lufga Regular' !important;
}

.container--a---mobile-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px;
}

.facaretdown--mobile-menu {
    border: none;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    background-color: var(--primary-background-color);
    color: var(--primary-red);
}

.facaretdown--mobile-menu svg {
    height: 15px;
    width: 15px;
}

.social-icons-mobilemeni {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.icon-social--child {
    padding: 5px;
    background-color: var(--primary-background-color);
    color: #000;
    height: 30px;
    border-radius: 6px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileSubMenu--1 {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 10px;
}

.mobileSubMenu--1:hover {
    background-color: var(--primary-background-color);
}

.mobileSubMenu--1:hover a {
    color: var(--primary-red) !important;
}

.loginandsignupButton {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;

    button {
        padding: 8px 20px;
        font-size: 1.2rem;
        /* border: none; */
        border-radius: 10px;
    }
}

.login-navbar {
    background-color: transparent;
    color: var(--primary-red);
    border: 1.4px solid var(--primary-red);
    /* background-color: var(--primary-background-color); */
}

.signup-navbar {
    background-color: var(--primary-red);
    color: #fff;
    border: none;
}

.support-icon-andtext button {
    padding: 10px;
    background-color: var(--primary-red);
    color: #fff;
    border: none;
    border-radius: 10px;
}

.suggestions-dropdown {
    position: absolute;
    width: 100%;
}

.suggestion-item {
    width: 100%;
    background-color: #fff;
    margin: 5px 5px;
    padding: 10px;
    z-index: 2000;
    position: relative;
    border-radius: 10px;
    border: 1px solid #c0c0c0;
    cursor: pointer;
}