/* loading.css */
.loading-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
}

.loading-overlay img {
    width: 150px;
    /* Adjust size as needed */
    animation: spin 5s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
    /* filter: drop-shadow(5px 5px 15px #6701c1); */
}

.loading-text {
    margin-top: 20px;
    font-size: 24px;
    color: #000;
}

@keyframes spin {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}