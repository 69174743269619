.category0-home0-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: #FFF2F2; */
}

.categoryRoute {
    display: grid;
    grid-template-columns: 19% 19% 19% 19% 19%;
    justify-content: space-between;
    padding: 50px 50px;
    gap: 20px;
}

.with20Pad {
    padding: 10px 50px;
    margin-bottom: 10px;
    border-bottom: .9px solid #c0c0c0;
}

.breadcrumbs {
    font-weight: 600;
    font-style: italic;
    color: grey;
}

.shopHeading {
    font-family: serif;
}

.headingShopping {
    padding: 0px 50px 20px 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.numProducts {
    font-size: .8rem;
    color: grey;
}

.productSingle {
    padding: 10px 50px;
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;

}

.productImages--single {
    display: flex;
    justify-content: start;
    flex-direction: row-reverse;
    justify-content: space-evenly;
}

.productgallery--images {
    max-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.productgallery--images img {
    min-width: 70px;
    min-height: 70px;
    max-height: 80px;
    max-width: 80px;
}

.productimage--featured {
    width: calc(100% - 100px);
}

.productimage--featured img {
    max-width: 100%;
}

.noofpackes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.tabnames {
    display: grid;
    grid-template-columns: 50% 50%;
}

.tabnames-child {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: grey;
    border-bottom: 1.5px solid grey;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tabnames-child.active {
    color: #000;
    border-bottom: 2px solid #000;

}

.tabs {
    padding: 10px;
    padding-top: 30px;
}

.tabcontent-child {
    padding: 10px;
}

.tabcontentp {
    font-weight: 400;
    color: rgb(79, 79, 79);
    ;
    font-size: 14px;
}

#productSecondRightPage {
    padding: 0px 20px;

    h2 {
        margin-bottom: 20px;
    }

    hr {
        margin-bottom: 20px;
    }
}

.allFuncData {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.typeofpurchase--child {
    padding: 5px 10px;
    border: 1px solid #000;
    margin: 0px 10px;
    border-radius: 5px;
    background-color: #fff;

}

.typeofpurchase--child.active {
    background-color: var(--primary-background-color);
    border: none;
}

.addtocartorbuy {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    margin-top: 20px;

    button {
        padding: 14px;
        font-size: .9rem;
        font-weight: 600;
        border: none;
        cursor: pointer;
    }
}

.addtocart {
    background-color: var(--primary-red);
    color: #fff;
}

.booknow {
    background-color: #fff;
    border: 1px solid var(--primary-red) !important;
    color: var(--primary-red);
}

.navbar-bottom-right svg {
    width: 20px;
    height: 20px;
    color: #000;
}

.cart--container {
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 20px;
}

.cart--summary {
    padding: 20px 20px;
}

.cartsummary-details {
    padding: 50px;
    border: 1px solid #000;
    border-radius: 20px;
}

.cart--container {
    padding: 20px 100px;
}

.cart--singledetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 15px;

}

.cart--product-image {
    max-width: 200px;
}

.cart--product-image img {
    max-width: 100%;
    border-radius: 15px;
}

.cart--products {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    gap: 10px;
}

.pcart-product--details {
    padding: 0px 0px 0px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cart--producttitle {
    font-size: 1.1rem;
    font-weight: 600;
}

.cart--productdescription {
    font-size: .9rem;
    color: rgb(89, 89, 89);
}

.quantityinfo {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.quantitybtn {
    background-color: var(--primary-background-color);
    color: var(--primary-red);
    padding: 5px;
    font-size: .9rem;
    border-radius: 10px;
}

.cartsummary--details-child {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 0px;
}

.btnGrp-flex {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;

}

.btnGrp-flex button,
.remove--button {
    padding: 10px;
}

.bookNow,
.remove--button {
    background-color: var(--primary-red);
    color: #fff;
    border: none;
}

.contactus--btn {
    border: 1px solid var(--primary-red);
    background-color: #fff;
    color: var(--primary-red);
}

.remove--button {
    border-radius: 10px;
}

.dashboardBreadCrumbs {
    height: 270px;
    background-image: url(https://pixio.dexignzone.com/xhtml/images/background/bg1.jpg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: center;
    object-fit: cover;
    position: relative;

    h1 {
        font-size: 3.2rem !important;
        color: #fff;
        z-index: 5;
    }

    p {
        font-size: 1.1rem;
        color: #fff;
        z-index: 5;
    }
}

.dashboardBreadCrumbs::before {
    opacity: 0.4;
    background: #000;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.dashboard--content {
    padding: 0px 70px;
    display: grid;
    grid-template-columns: 25% 75%;
    background-color: color(srgb 0.9995 0.9797 0.9543);
}

.dashboard--content--right {
    padding: 20px;

}

.fullWidth--dashboard {
    width: 100%;
    min-height: 100%;
    border: 1px solid #c0c0c0;
    border-radius: 20px;
}

.imgContainer--profileflexui {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
}

.imgContainer--profileflexui img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
}

.profileinfo--ui {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h2 {
        font-weight: 600;
        font-size: 1.9rem;
    }

    p {
        font-weight: 500;
        color: var(--primary-red);
    }
}

.profileflex--uploadimage {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.profile--topbar {
    padding: 20px;
    border-bottom: 1px solid #c0c0c0;
}

.profile-input--fieldscontainer {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    width: 100%;
    padding: 20px;
    row-gap: 10px;
}

.profile-input--fieldscontainer label {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        padding-left: 5px;
        font-weight: 600;
    }

    input {
        width: 90%;
        height: 50px;
        border-radius: 15px;
        border: 1.3px solid #000;
        margin-top: 5px;
        padding: 10px;
    }
}

#verifyID {
    position: relative;
}

.verifyID--btn {
    padding: 10px;
    position: absolute;
    bottom: 5px;
    right: calc(10% + 10px);
    border-radius: 10px;
    border: 1px solid var(--primary-red);
    background-color: var(--primary-background-color);
    color: var(--primary-red);
}

.saveProfile--btndiv {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 10px 60px;
    gap: 10px;
}

.saveProfile {
    background-color: green;
    padding: 10px 10px;
    border-radius: 10px;
    border: none;
    color: #fff;
}

.discardChanges {
    display: flex;
    background-color: var(--primary-red);
    color: #fff;
    padding: 10px 10px;
    border: none;
    border-radius: 10px;
}

.addressProofile--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.shippingandbillingadderss-infotext {
    width: 100%;
    text-align: left;
    /* padding: 20px; */
}

.addresses {
    display: grid;
    grid-template-columns: 50% 50%;
    /* gap: 20px; */
    width: 100%;
}

.address-child-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    border: 1px solid var(--primary-red);
    border-radius: 20px;
    margin: 20px;
    padding: 20px;
}

.addressChildName {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.address-Line1,
.state--city--address,
.mobileNumber--address,
.email--address {
    color: grey;
}

.verification-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: .9rem;
}

.contactus-container {
    padding: 0px 50px;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
}

.contactus-container section {
    padding: 20px;
    width: 100%;
}

.formWidget {
    background-color: var(--primary-background-color);
    width: 100%;
    padding: 20px;
    border-radius: 20px;
}

.formWidget h1 {
    color: var(--primary-red);
    padding-bottom: 20px;
}

.formWidget form label p {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 600;
}

.formWidget form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formWidget form label input {
    padding: 10px;
    width: 90%;
    border: 1px solid #c0c0c0;
    border-radius: 10px;
}

.formWidget form label textarea {
    width: 90%;
    max-width: 100%;
    min-height: 200px;
}

.contactus-container--widgets {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
}

.widgetcontactus-child1 {
    width: 100%;
    justify-content: start;
    align-items: center;
    display: flex;
    font-size: 20px;
    color: var(--primary-red);
}

.h1Contact {
    margin-top: 30px;
    font-size: 2rem;
}

.submitContact {
    width: 90%;
    background-color: var(--primary-red);
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
}

.aboutus-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.aboutus-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #333;
}

.aboutus-container section {
    margin-bottom: 20px;
}

.aboutus-container h2 {
    margin-bottom: 10px;
    font-size: 1.75rem;
    color: #4CAF50;
}

.aboutus-container p,
.aboutus-container ul {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}

.aboutus-container ul {
    list-style-type: disc;
    padding-left: 20px;
}

.aboutus-container li {
    margin-bottom: 10px;
}

.product-range ul,
.product-range ul li {
    list-style-type: disc !important;
    color: #000;
}