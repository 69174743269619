/* Responsive styles */
@media (max-width: 1320px) {

    .navbar-top-container,
    .navbar-bottom {
        padding: 0 60px;
        /* display: none !important; */

    }

    .support-icon-andtext {

        display: none;
        gap: 8px;
    }
}

@media (max-width: 1024px) {

    .navbar-top-container,
    .navbar-bottom {
        padding: 0 40px !important;
    }



    .logo-container {
        max-height: 80px;
    }

    .logo-container img {
        max-height: 80px;
    }

    .support-icon-andtext {

        display: none;
        gap: 8px;
    }
}

@media (max-width: 768px) {

    .navbar-top-container,
    .navbar-bottom {
        padding: 0 50px !important;
    }

    .logo-container {
        max-height: 60px;
    }

    .logo-container img {
        max-height: 60px;
    }

    .support-icon-andtext {

        display: none;
        gap: 8px;
    }

    .auth-page-component {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        /* margin: 10px; */
    }

    .auth-component-wrapper {
        padding: 30px;
        margin-top: 50px;
    }

    .end-side-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .category-home {
        padding: 24px 0px;
    }

    .category-img--div--fs01 {
        max-width: 105px;
        max-height: 105px;
        margin-bottom: 12px;
    }

    .categories-scroll-view {
        gap: 0px;
    }

    .category-item {
        max-width: 125px;
        min-width: 125px;
        word-wrap: break-word;
        /* Break long words onto a new line */
        overflow-wrap: break-word;
        /* Break long words onto a new line */
        white-space: normal;
        text-align: center;
        /* Allow text to wrap to the next line */
    }


    .category-div-fs01 {
        max-width: 125px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .category-img--div--fs01 {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .category-img--div--fs01 img {
        min-width: 100%;
        height: 105px !important;
        max-height: 105px !important;
        object-fit: contain;
        min-height: 105px;
    }

    .categories-scroll-view:first-child {
        padding-left: 30px;
    }

    .heading-title-div--component {
        padding: 0px 20px !important;
    }

    .heading-title--component {
        font-size: 1.5rem;
    }

    .heading-title--component-01 {
        font-size: .9rem;
        color: grey;
    }

    .product-slider--home {
        padding: 0px 20px;
    }

    .product-title--componentfs {
        font-size: 1rem;
        font-weight: 600;
        color: rgb(52, 52, 52);
    }

    .booknow--fast-component {
        padding: 8px 10px;
        font-weight: 500;
        font-size: 0.7rem;

    }

    .product-functions--componentfs {
        gap: 10px;
    }

    .product--counter-quantity {
        font-size: .8rem;
    }

    .mostWanted-grid {
        grid-template-columns: 49% 49%;
        justify-content: space-between;
        padding: 20px 20px;
    }

    .product-grid--productstyle {
        grid-template-columns: 49% 49%;
        padding: 0px 20px;
    }

    .footer-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 70px 20px;
    }

    .footer-container-sections,
    .footer-section-child,
    .footer-sections-contact-creds,
    .footer-child--fs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .footer-child--fs--1 {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        justify-content: center;
        padding: 0px 10px;
    }

    .footer-child--fs--1 li {
        width: 100%;
        font-size: .8rem;
        display: flex;
        text-align: center;
        flex-direction: row;
        justify-content: center;
    }

    .categoryRoute {
        grid-template-columns: 49% 49%;
        padding: 20px;
        place-content: center;
        place-items: center;
        row-gap: 20px;
    }

    .with20Pad {
        padding: 5px 20px;
    }

    .breadcrumbs {
        font-size: 0.8rem;
    }



    .headingShopping {
        padding: 10px 20px;
        padding-top: 0px;

    }

    .productSingle {
        display: flex;
        flex-direction: column;
        padding: 0px;

    }

    .productImages--single {
        flex-direction: column;
        width: 100%;
        align-items: center;

    }

    .productimage--featured {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
    }

    .productimage--featured img {
        min-width: 100%;
    }

    .productgallery--images {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 200px;
        max-width: 400px;
        overflow-x: scroll;
        /* overflow-x: auto; */
    }

    #productSecondRightPage {
        padding-top: 30px;
    }

    .addtocartorbuy.fixed-bottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 20px;
        width: calc(100% - 40px);
        /* Adjust based on your padding/margin */
        margin: 0 auto;
        background-color: #fff;
    }

    .dashboard--content {
        display: flex;
        flex-direction: column;
        padding: 0px;
    }

    .dashboard--content--right {
        width: 100%;
    }

    .dashboardlink--container {
        margin-top: -40px !important;
        width: 80%;
        align-self: center;
    }

    .profile-input--fieldscontainer {
        display: flex;
        flex-direction: column;
    }

    .profile-input--fieldscontainer label input {
        width: 100%;
    }

    .verifyID--btn {
        right: 10px;
    }

    .saveProfile--btndiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .addresses {
        grid-template-columns: 100%;
    }

    .cart--container {
        display: flex;
        flex-direction: column;
    }

    .cart--container {
        padding: 0px 20px;
    }

    .cart--products {
        width: 100%;
        margin-top: 10px;
    }

    .contactus-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .contactus-container section {
        padding: 10px;
    }

    .footer-sections-contact-creds {
        min-width: 100px !important;
    }

    .product--wrapper-grid-1-mobile img {
        min-height: 200px;
        max-height: 200px;
    }
}